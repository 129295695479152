// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  font-size: 62.5%;
  text-size-adjust: 100%;

  /** primary **/
  --ion-color-primary: #4cace9;
  --ion-color-primary-rgb: 76, 172, 233;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4397cd;
  --ion-color-primary-tint: #5eb4eb;

  /** secondary **/
  --ion-color-secondary: #e64461;
  --ion-color-secondary-rgb: 230, 68, 97;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ca3c55;
  --ion-color-secondary-tint: #e95771;

  /** tertiary **/
  --ion-color-tertiary: #b3ccb9;
  --ion-color-tertiary-rgb: 179, 204, 185;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #9eb4a3;
  --ion-color-tertiary-tint: #bbd1c0;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** specific variable   **/
  --ion-font-family: var(--gfl-font_family_1);

  /** gfl general **/
  --gfl-color_bt_primary_bgd: #ffffff;
  --gfl-color_bt_primary_border: #e64461;
  --gfl-color_txt_primary: #e64461;
  --gfl-color_bt_secondary_bgd: #4cace9;
  --gfl-color_bt_secondary_border: #4cace9;
  --gfl-color_txt_secondary: #ffffff;
  --gfl-color_bt_thirdly_bgd: #ffffff;
  --gfl-color_bt_thirdly_border: #4cace9;
  --gfl-color_txt_thirdly: #4cace9;
  --gfl-color_bt_fourthly_bgd: #b3ccb9;
  --gfl-color_bt_fourthly_border: #b3ccb9;
  --gfl-color_txt_fourthly: #ffffff;
  --gfl-color_bt_fifthly_bgd: #e64461;
  --gfl-color_bt_fifthly_border: #e64461;
  --gfl-color_txt_fifthly: #ffffff;
  --gfl-color_bt_sixthly_bgd: #4cace9;
  --gfl-color_bt_sixthly_border: #4cace9;
  --gfl-color_txt_sixthly: #ffffff;
  --gfl-color_header_bgd: #102e52;
  --gfl-color_txt_header: #ffffff;
  --gfl-color_menu_bgd: #102e52;
  --gfl-color_menu_border_active: var(--gfl-color_primary);
  --gfl-color_menu_bgd_active: #4cace9;
  --gfl-color_txt_menu: #ffffff;
  --gfl-color_txt_menu_active: #49a5e1;
  --gfl-color_menu_border: #051a32;
  --gfl-color_body_bgd: #ffffff;
  --gfl-color_body_1_bgd: #ffffff;
  --gfl-color_body_2_bgd: #ffffff;
  --gfl-color_body_3_bgd: #ffffff;
  --gfl-color_txt_body: #3d4549;
  --gfl-color_txt_1_body: #3d4549;
  --gfl-color_txt_2_body: #3d4549;
  --gfl-color_txt_3_body: #3d4549;
  --gfl-color_slide_1_bgd: #6ac5de;
  --gfl-color_slide_2_bgd: #b3ccb9;
  --gfl-color_slide_3_bgd: #ffa851;
  --gfl-color_slide_1_txt: #ffffff;
  --gfl-color_slide_2_txt: #ffffff;
  --gfl-color_slide_3_txt: #ffffff;
  --gfl-color_homeBtn_1_bgd: #f2f2f2;
  --gfl-color_homeBtn_2_bgd: #f2f2f2;
  --gfl-color_homeBtn_3_bgd: #f2f2f2;
  --gfl-color_homeBtn_4_bgd: #f2f2f2;
  --gfl-color_subheader_bgd: #e64461;
  --gfl-color_subheader_1_bgd: #102e52;
  --gfl-color_subheader_2_bgd: #e64461;
  --gfl-color_subheader_3_bgd: #e64461;
  --gfl-color_subheader_txt: #ffffff;
  --gfl-color_subheader_1_txt: #ffffff;
  --gfl-color_subheader_2_txt: #ffffff;
  --gfl-color_subheader_3_txt: #ffffff;
  --gfl-color_list_header_1_bgd: #102e52;
  --gfl-color_list_header_2_bgd: #102e52;
  --gfl-color_list_header_3_bgd: #102e52;
  --gfl-color_list_header_1_txt: #ffffff;
  --gfl-color_list_header_2_txt: #ffffff;
  --gfl-color_list_header_3_txt: #ffffff;
  --gfl-color_footbar_bgd: #102e52;
  --gfl-color_welcome_footbar_bgd: #ffffff;
  --gfl-color_bt_footbar: #ffffff;
  --gfl-color_bt_footbar_active: #4cace9;
  --gfl-color_body_1_tablet_bgd: #4cace9;
  --gfl-color_body_2_tablet_bgd: #4cace9;
  --gfl-color_body_3_tablet_bgd: #4cace9;
  --gfl-color_body_item_1_tablet_bgd: #ffffff;
  --gfl-color_body_item_2_tablet_bgd: #ffffff;
  --gfl-color_body_item_3_tablet_bgd: #ffffff;
  --gfl-color_subheader_1_tablet_bgd: #f7efdd;
  --gfl-color_subheader_2_tablet_bgd: #f7efdd;
  --gfl-color_subheader_3_tablet_bgd: #f7efdd;
  --gfl-color_txt_1_tablet_body: #3d4549;
  --gfl-color_txt_2_tablet_body: #3d4549;
  --gfl-color_txt_3_tablet_body: #ffffff;
  --gfl-color_txt_label: var(--gfl-color_primary);
  --gfl-color_txt_label_1_tablet_body: #3d4549;
  --gfl-color_txt_label_2_tablet_body: #3d4549;
  --gfl-color_txt_label_3_tablet_body: #102e52;
  --gfl-color_budget_1_bgd: #4cace9;
  --gfl-color_budget_3_bgd: #b3ccb9;
  --gfl-color_budget_2_bgd: #e64461;
  --gfl-color_budget_4_bgd: #46bfbd;
  --gfl-color_budget_5_bgd: #fdb45c;
  --gfl-color_budget_6_bgd: #949fb1;
  --gfl-color_budget_7_bgd: #4d5360;
  --gfl-color_resume_header_bgd: #f5f5f5;
  --gfl-color_resume_header_border_bgd: transparent;
  --gfl-color_resume_content_bgd: #ffffff;
  --gfl-color_resume_content_odd_bgd: #f5f5f5;
  --gfl-color_resume_footer_bgd: #f7efdd;
  --gfl-color_primary: #4cace9;
  --gfl-color_secondary: #e64461;
  --gfl-color_thirdly: #b3ccb9;
  --gfl-color_fourthly: #feb75b;
  --gfl-color_warning: #feb75b;
  --gfl-color_success: #23c04f;
  --gfl-color_danger: #e64461;
  --gfl-height_header_private: 54px;
  --gfl-height_header_public: 80px;
  --gfl-height_logo_private: 22px;
  --gfl-height_logo_public: 70px;
  --gfl-border_radius: 7px;
  --gfl-color_customerChoice_bgd: #4cace9;
  --gfl-color_customerChoiceBtn_1_bgd: #4cace9;
  --gfl-color_customerChoiceBtn_2_bgd: #e74461;
  --gfl-color_customerChoiceBtn_1_txt: #ffffff;
  --gfl-color_customerChoiceBtn_2_txt: #ffffff;
  --gfl-color_compareItemLabel_txt: #2f2f2f;
  --gfl-color_compareItemValue_txt: #2f2f2f;
  --gfl-color_compareSubItemLabel_txt: #4cace9;
  --gfl-color_compareSubItemValue_txt: #4cace9;
  --gfl-color_policy_1_border: #09aa88;
  --gfl-color_policy_2_border: #fda20d;
  --gfl-color_policy_3_border: #f20297;
  --gfl-color_policy_4_border: #a70125;
  --gfl-color_policy_5_border: #f53123;
  --gfl-color_policy_6_border: #ffdf90;
  --gfl-color_policy_7_border: #abd9e9;
  --gfl-color_policy_8_border: #4575b7;
  --gfl-color_policy_9_border: #552789;
  --gfl-color_policy_10_border: #6dae0c;
  --gfl-color_compareChooseOffer_bgd: #4cace9;
  --gfl-color_compareChooseOffer_txt: #ffffff;
  --gfl-font_family_1: 'Lato R', sans-serif;
  --gfl-font_family_2: 'Lato B', sans-serif;
  --gfl-font_family_3: Arial;

  /** gfl-button **/
  --gfl-button-height: 4.6rem !important;
  --gfl-button-border-radius: var(--gfl-border_radius);
  --gfl-button-font-family: var(--gfl-font_family_3);

  /** gfl header not connected **/
  --gfl-not-connected-header-background-color: #ffffff;
  --gfl-not-connected-header-back-button-color: var(--gfl-color_primary);

  /** gfl header connected **/
  --gfl-connected-header-background-color: var(--gfl-color_header_bgd);
  --gfl-connected-header-button-color: var(--gfl-color_txt_header);

  /** gfl content **/
  --gfl-content-background-color: var(--gfl-color_body_bgd);

  /** gfl lang-selector **/
  --gfl-lang-selector-item-color: var(--gfl-color_primary);
  --gfl-lang-selector-border-color: var(--gfl-color_primary);
  --gfl-lang-selector-item-color-active: var(--gfl-color_menu_bgd);

  /** gfl sidebar menu **/
  --gfl-sidebar-background-color: var(--gfl-color_menu_bgd);
  --gfl-sidebar-item-background-color: var(--gfl-color_menu_bgd);
  --gfl-sidebar-item-border-color: var(--gfl-color_menu_border);
  --gfl-sidebar-item-color: #ffffff;
  --gfl-sidebar-item-active-border-color: var(--gfl-color_primary);
  --gfl-sidebar-item-active-color: var(--gfl-color_primary);

  /** gfl footer **/
  --gfl-footer-background-color: var(--gfl-color_footbar_bgd);
  --gfl-footer-item-color: var(--gfl-color_bt_footbar);
  --gfl-footer-item-active-color: var(--gfl-color_primary);

  /** gfl form **/
  --gfl-form-item-border-radius: var(--gfl-border_radius);
  --gfl-form-item-border-color: #dddddd;
  --gfl-form-item-background-color: #edefef;

  /** gfl home page **/
  --gfl-homePage-btn-1-background-color: var(--gfl-color_homeBtn_1_bgd);
  --gfl-homePage-btn-2-background-color: var(--gfl-color_homeBtn_2_bgd);
  --gfl-homePage-btn-3-background-color: var(--gfl-color_homeBtn_3_bgd);
  --gfl-homePage-btn-4-background-color: var(--gfl-color_homeBtn_4_bgd);

  /** gfl max width display  for not logged in customer **/
  --gfl-desktop-max-container: 750px;
}
