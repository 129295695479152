/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Fonts Declarations
// --------------------------------------------------

@font-face {
  font-family: 'Lato R';
  src: url('./assets/fonts/lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato B';
  src: url('./assets/fonts/lato/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato L';
  src: url('./assets/fonts/lato/Lato-Light.ttf');
}

// Global styling
// ---------------------------------------------------
html {
  font-family: var(--gfl-font_family_1) !important;
}

body {
  font-size: 1.4rem;
}

*:focus {
  outline: none;
}

/* fix intern padding-left in focused ion-select option */
.popover-viewport ion-list.md ion-item.ion-focused ion-label {
  padding-left: 4px;
}

.gfl-filigrane {
  position: absolute;
  bottom: 0;
}

.gfl-date-wrapper {
  --width: 350px;
}

.dev-flag-welcome {
  position: absolute;
  left: 10px;
  display: inline-block;
  background-color: red;
  border-radius: 7px;
  padding: 3px 4px 0;
  font-weight: bold;
  margin-right: 10px;
  ion-icon {
    font-size: 3.5rem;
    color: #ffffff;
  }
}

.gfl-content {
  --background: var(--gfl-content-background-color);
  background: var(--gfl-content-background-color);
}

.gfl-connected-header {
  ion-toolbar {
    --min-height: var(--gfl-height_header_private);
  }
}

.gfl-not-connected-header {
  ion-toolbar {
    --min-height: var(--gfl-height_header_public);
    --background: var(--gfl-not-connected-header-background-color);
  }

  ion-title {
    height: var(--gfl-height_header_public);
    padding-left: 0;
    padding-right: 0;
  }

  .gfl-back-button {
    --color: var(--gfl-not-connected-header-back-button-color) !important;
  }

  .menu-logo {
    height: var(--gfl-height_logo_public);
    max-width: 70%;
    margin: 0 auto;
  }
}

.is-mandate-img {
  filter: grayscale(1);
}

.gfl-offline {
  filter: grayscale(1);
  opacity: 0.4;
  cursor: default;
}

.gfl-button {
  --border-width: 1px;
  --border-radius: var(--gfl-button-border-radius) !important;
  border-radius: var(--gfl-border_radius) !important;
  height: var(--gfl-button-height) !important;
  font-family: var(--gfl-button-font-family);
  font-size: 1.4rem;
  --box-shadow: none;
  text-transform: uppercase;
  white-space: break-spaces;
}

.gfl-no-button {
  background: none;
  border: none;
  height: auto !important;
}

.gfl-form-cancel-btn {
  --background: #ffffff;
  --background-hover: #ffffff;
  --background-activated: #ffffff;
  --background-focused: #ffffff;
  --color: var(--gfl-color_danger);
  --color-activated: var(--gfl-color_danger);
  --color-focused: var(--gfl-color_danger);
  --border-color: var(--gfl-color_danger);
}

.gfl-form-validate-btn {
  --background: var(--gfl-color_bt_sixthly_bgd);
  --background-hover: var(--gfl-color_bt_sixthly_bgd);
  --background-activated: var(--gfl-color_bt_sixthly_bgd);
  --background-focused: var(--gfl-color_bt_sixthly_bgd);
  --color: var(--gfl-color_txt_sixthly);
  --color-activated: var(--gfl-color_txt_sixthly);
  --color-focused: var(--gfl-color_txt_sixthly);
  --border-color: var(--gfl-color_bt_sixthly_border);
}

.gfl-alert-btn {
  border-radius: var(--gfl-border_radius) !important;

  &.sc-ion-alert-ios {
    border-radius: 0 !important;
  }
}

.gfl-alert-validate-btn {
  background-color: var(--gfl-color_success) !important;
  color: #ffffff !important;
}

.gfl-alert-info-btn {
  background-color: var(--gfl-color_subheader_2_bgd) !important;
  color: #ffffff !important;
}

.gfl-alert-cancel-btn {
  background-color: var(--gfl-color_danger) !important;
  color: #ffffff !important;
}

.gfl-sidebar-ion-item {
  --background: var(--gfl-sidebar-item-background-color);
  --color: var(--gfl-sidebar-item-color);
  --detail-icon-color: var(--gfl-sidebar-item-color);
  --detail-icon-opacity: 1;
  border-top: 1px solid var(--gfl-sidebar-item-border-color);
  border-left: 5px solid transparent;
  font-family: var(--gfl-font_family_2);

  &.active {
    border-left-color: var(--gfl-sidebar-item-active-border-color);
    --color: var(--gfl-sidebar-item-active-color);
    --detail-icon-color: var(--gfl-sidebar-item-active-color);
  }

  gfl-sprite {
    width: 24px;
    margin-right: 20px;
  }
}

.gfl-field {
  --highlight-height: 0 !important;
}

.gfl-footer {
  height: 65px;
}

.gfl-toast-success {
  --border-radius: var(--gfl-border_radius);
  --background: var(--gfl-color_success);
  --color: #ffffff;
  --button-color: #ffffff;
}

.gfl-toast-warning {
  --border-radius: var(--gfl-border_radius);
  --background: var(--gfl-color_warning);
  --color: #ffffff;
  --button-color: #ffffff;
}

.gfl-toast-error {
  --border-radius: var(--gfl-border_radius);
  --background: var(--gfl-color_danger);
  --color: #ffffff;
  --button-color: #ffffff;
}

.plt-desktop {
  .gfl-max-container {
    max-width: var(--gfl-desktop-max-container);
  }
}

.plt-desktop,
.plt-tablet {
  gfl-header-tablet ion-toolbar {
    margin: auto;
  }

  ion-footer {
    ion-grid {
      height: 65px;
      transition: height 0.3s ease;
    }
  }

  button.button {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .back-button {
    display: none;
  }

  @media only screen and (min-height: 569px), only screen and (min-width: 415px) {
    .modal-wrapper {
      left: calc(50% - (600px / 2)) !important;
      top: calc(50% - (630px / 2)) !important;
      position: absolute !important;
      width: 600px !important;
      height: 630px !important;
    }
  }
}

@media (min-width: 960px) {
  .plt-desktop,
  .plt-tablet {
    gfl-header-tablet ion-toolbar,
    gfl-footer-tablet ion-toolbar,
    ion-tabs .tabbar,
    .gfl-container {
      margin: 0;
      padding-right: calc((100% - 960px) / 2);
      padding-left: calc((100% - 960px) / 2);
    }
  }
}

// Form styling
// ---------------------------------------------------

//ion-item.item-has-focus {
//  transition: all 0.15s ease;
//  border-left-width: 10px !important;
//  border-color: var(--gfl-color_thirdly) !important;
//
//  &.ion-invalid {
//    border-color: var(--gfl-color_danger) !important;
//  }
//}

.gfl-form-item {
  font-size: 1.4rem;
  border-radius: var(--gfl-form-item-border-radius);
  border: 1px solid var(--gfl-form-item-border-color) !important;
  //background-color: var(--gfl-form-item-background-color);

  &.has-focus {
    transition: all 0.15s ease;
    border-left-width: 10px !important;
  }

  &.ion-invalid.ion-touched {
    border-color: var(--gfl-color_danger) !important;
  }

  &.ion-valid.ion-touched {
    border-color: var(--gfl-color_success) !important;
  }
}

.gfl-input,
.gfl-select {
  margin-bottom: 0;
  --padding-start: 13px;
  --padding-bottom: 15px;
  --padding-top: 14px;
  --padding-end: 8px;

  border: 1px solid var(--gfl-form-item-border-color) !important;
  background-color: var(--gfl-form-item-background-color);
  border-radius: var(--gfl-border_radius);
}

.gfl-close-btn {
  position: absolute;
  right: 0;
  bottom: 0px;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 5rem !important;
  z-index: 20;
  background-color: transparent;
  display: flex;
  color: #000000;

  .icon {
    padding: 0 !important;
    color: #ffffff;
  }
}

.tablet .gfl-close-btn {
  top: 15px;
  bottom: unset;
}

.gfl-validation-section {
  font-size: 1.2rem;
  font-weight: 500;
  --color: #666666 !important;
  color: #666666 !important;

  .gfl-validation-message {
    margin-bottom: 13px;
    margin-top: 0;
  }
}

// ionic-selectable component
// ---------------------------------------------------
.ionic-selectable-modal {
  .in-toolbar {
    --background: var(--gfl-color_primary);
  }

  ion-searchbar {
    padding-top: 0;
    padding-bottom: 0;
    width: calc(100% - 42px);

    .searchbar-input {
      border-radius: 13px;
      box-shadow: none;
      border: 1px solid #dddddd;
      background: #edefef;
    }
  }

  .ionic-selectable-item {
    --color: var(--gfl-color_txt_1_body) !important;
    color: var(--gfl-color_txt_1_body) !important;
  }
}

// Alerts Styling
// ---------------------------------------------------
.pairing-code-alert {
  .alert-head {
    font-size: 1.6rem;
    text-align: center;
  }

  .pairing-code-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin: 20px auto 10px;
    padding: 0;
    border: 1px solid;
    cursor: copy;
  }

  .clipboard {
    display: flex;
    text-align: center;
    font-size: 32px;
    padding: 5px 0;
  }
}

.alert-wrapper {
  .alert-radio-label {
    white-space: normal;
    font-size: 1.3rem;
  }
}

.alert-button-group {
  .alert-button {
    color: #ffffff;
    border-radius: var(--gfl-border_radius);

    &.sc-ion-alert-ios {
      border-radius: 0;
    }

    &:first-child {
      background-color: var(--gfl-color_danger);
    }

    &:last-child {
      background-color: var(--gfl-color_success);
    }
  }
}

.popover-viewport {
  ion-list {
    margin: 0;

    ion-item:last-child {
      --inner-border-width: 0;
    }

    ion-radio {
      width: 0;
      margin: 0;
    }
  }
}

.alert-wrapper {
  border-radius: var(--gfl-border_radius) !important;
}

// Tools styling
// ---------------------------------------------------

.gfl-section-container {
  //height: calc(100% + 1px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.gfl-main-section {
  overflow-y: scroll;
  overflow-x: hidden;
}

.gfl-flex-column {
  display: flex;
  flex-direction: column;
}

.gfl-flex-row {
  display: flex;
  flex-direction: row;
}

.gfl-flex-none {
  flex: none;
}

.gfl-flex-1 {
  flex: 1;
}

.gfl-flex-wrap {
  flex-wrap: wrap;
}

.gfl-full-height {
  height: 100%;
}

.gfl-full-max-height {
  max-height: 100%;
}

.gfl-full-width {
  width: 100%;
}

.gfl-full-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gfl-no-padding {
  padding: 0;
}

.gfl-overflow-hidden {
  overflow: hidden;
}

.gfl-relative {
  position: relative;
}

.gfl-scroll-x {
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    //border: 2px solid #ffffff; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.gfl-scroll-y {
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    //border: 2px solid #ffffff; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.glf-not-visible {
  visibility: hidden !important;
}

.gfl-no-wrap {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gfl-wrap {
  white-space: normal !important;
}

.toast-button {
  color: #ffffff;
}

.gfl-modal-no-background {
  .modal-wrapper {
    background-color: transparent;
  }
}

.gfl-board-body {
  display: flex;
  width: 100%;

  .scroll {
    overflow-y: auto;
  }
}

.gfl-board {
  width: 100%;
  background-color: #ffffff;

  th {
    color: #3f3f3f;
    background: #ebebeb;
    white-space: nowrap;
  }

  th:first-child {
    border-top-left-radius: 3px;
  }

  th:last-child {
    border-top-right-radius: 3px;
    border-right: none;
  }

  tr {
    border-top: 1px solid #c1c3d1;
    border-bottom: 1px solid #c1c3d1;
    color: #666b85;
    font-size: 1.6rem;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: #eee;
  }

  thead tr {
    border-top: 1px solid #c1c3d1;
    border-bottom: 1px solid #c1c3d1;
  }

  th {
    font: 1.3rem var(--gfl-font_family_2);
    padding: 15px 5px;
    background: transparent;
  }

  tr,
  td {
    font-size: 1.2rem;
    padding: 12px 5px;
    text-align: left;

    &.expired {
      color: var(--gfl-color_danger);
    }
  }
}
